// Zabor.jsx
import React, { useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader, RepeatWrapping, sRGBEncoding } from 'three';
import materials from './materials';

const Zabor = ({ collectionIndex, meshMaterialIndices, isThirdMeshVisible }) => {
  const { scene } = useThree();
  const modelRef = useRef();
  const textureLoader = new TextureLoader();

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load('/mesh/oneplace/zabor/zaborall3.glb', (gltf) => {
      modelRef.current = gltf.scene;
      scene.add(gltf.scene);
      modelRef.current.position.set(0, 0.45, 0);
      // console.log('Модель забор загружена:', gltf.scene);
      updateCollectionAndMeshMaterials();
    });

    return () => {
      if (modelRef.current) {
        scene.remove(modelRef.current);
      }
    };
  }, [scene]);

  useEffect(() => {
    updateCollectionAndMeshMaterials();
  }, [collectionIndex, meshMaterialIndices, isThirdMeshVisible]);

  const updateCollectionAndMeshMaterials = () => {
    if (!modelRef.current) return;
    const collections = modelRef.current.children;
  
    collections.forEach((collection, idx) => {
      collection.visible = idx === collectionIndex;
      if (idx === collectionIndex) {
        collection.children.forEach((mesh, meshIdx) => {
          // Управление видимостью третьего меша
          if (meshIdx === 2) {
            mesh.visible = isThirdMeshVisible;
          }
  
          const materialIndex = meshMaterialIndices[meshIdx];
          const materialConfig = materials.zabor[materialIndex];
          if (materialConfig && mesh.material) { // Проверка наличия материала у меша
            const baseColorMap = textureLoader.load(materialConfig.baseColor, onLoadTexture);
            const roughnessMap = textureLoader.load(materialConfig.roughness, onLoadTexture);
            const normalMap = textureLoader.load(materialConfig.normalMap, onLoadTexture);
  
            // Установка масштаба текстуры
            const textureScale = materialConfig.scale || 3.5;
            baseColorMap.repeat.set(textureScale, textureScale);
            roughnessMap.repeat.set(textureScale, textureScale);
            normalMap.repeat.set(textureScale, textureScale);
  
            // Создаем новый экземпляр материала для каждого меша
            const newMaterial = mesh.material.clone(); // Теперь здесь не должно возникать ошибки
            newMaterial.map = baseColorMap;
            newMaterial.roughnessMap = roughnessMap;
            newMaterial.normalMap = normalMap;
            newMaterial.metalness = materialConfig.metalness || 0.6;
            newMaterial.roughness = materialConfig.roughnessValue || 1;
            newMaterial.needsUpdate = true;
  
            mesh.material = newMaterial;
          }
        });
      }
    });
  };

  const onLoadTexture = (texture) => {
    texture.encoding = sRGBEncoding;
    texture.wrapS = texture.wrapT = RepeatWrapping;
    texture.needsUpdate = true;
  };

  return null;
};

export default Zabor;
