// EpitaphInput.jsx
import React, { useState } from 'react';

const EpitaphInput = ({ onSubmitEpitaph }) => {
  const [epitaph, setEpitaph] = useState('');

  const handleSubmitEpitaph = () => {
    onSubmitEpitaph(epitaph);
  };

  return (
    <div>
      <textarea value={epitaph} onChange={(e) => setEpitaph(e.target.value)} />
      <button onClick={handleSubmitEpitaph}>Готово</button>
    </div>
  );
};

export default EpitaphInput;