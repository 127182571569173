// GravirovkaImages.jsx
import React from 'react';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';

const GravirovkaImages = ({ gravImagesIndex, gravImagesArray, positionY, positionZ, scale }) => {
  const texture = useLoader(TextureLoader, gravImagesArray[gravImagesIndex].baseColor);
  const alphaMap = useLoader(TextureLoader, gravImagesArray[gravImagesIndex].alphaMap);

  return (
    <mesh 
      position={[0.7155, positionY, positionZ]} // Используйте positionY для установки позиции Y
      scale={[scale, scale, 1]} 
      rotation={[0, Math.PI / -2, 0]}>
      <planeBufferGeometry attach="geometry" args={[0.35, 0.35]} />
      <meshBasicMaterial attach="material" map={texture} alphaMap={alphaMap} transparent={true} />
    </mesh>
  );
};

export default GravirovkaImages;