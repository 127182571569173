// ColorIndicator.jsx
import React from 'react';
import materials from './materials'; // Убедитесь, что путь к файлу materials.js указан правильно

const ColorIndicator = ({ materialIndex, type }) => {
  // Получаем массив конфигураций материалов для данного типа (BaseOne или BaseTwo)
  const materialConfigs = materials[type];

  // Проверяем, существуют ли конфигурации материалов для данного типа
  if (!materialConfigs || materialConfigs.length === 0) {
    // Если нет, возвращаем null или запасной UI
    return null;
  }

  // Получаем конфигурацию материала для текущего индекса
  const materialConfig = materialConfigs[materialIndex];

  // Проверяем, существует ли конфигурация материала для данного индекса
  if (!materialConfig) {
    // Если нет, возвращаем null или запасной UI
    return null;
  }

  // Возвращаем элемент img, который будет служить индикатором текстуры
  return (
    <img
      src={materialConfig.baseColor} // Используем baseColor как источник изображения
      alt="Texture preview"
      style={{
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        objectFit: 'cover',
        display: 'inline-block',
        margin: '0 10px',
      }}
    />
  );
};

export default ColorIndicator;