import { useEffect } from 'react';
import { useLoader, useThree } from '@react-three/fiber';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { PMREMGenerator } from 'three';

const Background = ({ hdrBackgroundPath, hdrEnvironmentPath }) => {
  const { gl, scene } = useThree();
  // Загружаем HDR-текстуру для фона
  const hdrBackgroundTexture = useLoader(RGBELoader, hdrBackgroundPath);
  // Загружаем HDR-текстуру для окружения объектов
  const hdrEnvironmentTexture = useLoader(RGBELoader, hdrEnvironmentPath);

  useEffect(() => {
    const pmremGenerator = new PMREMGenerator(gl);
    pmremGenerator.compileEquirectangularShader();

    // Создаем текстуру окружения для фона сцены
    const bgEnvMap = pmremGenerator.fromEquirectangular(hdrBackgroundTexture).texture;
    scene.background = bgEnvMap;

    // Создаем текстуру окружения для материалов
    const envMap = pmremGenerator.fromEquirectangular(hdrEnvironmentTexture).texture;
    scene.environment = envMap;

    hdrBackgroundTexture.dispose();
    hdrEnvironmentTexture.dispose();
    pmremGenerator.dispose();

    return () => {
      scene.background = null;
      scene.environment = null;
      bgEnvMap.dispose();
      envMap.dispose();
    };
  }, [hdrBackgroundTexture, hdrEnvironmentTexture, gl, scene]);

  return null;
};

export default Background;