// materials.js
const materials = {
  oneplace: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для стеллажа
  ],
  twoplaces: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для тумбы
  ],
  trotuar: [
    {
      baseColor: '/textures/Trotuar_Plitka_BaseColor.jpg',
      roughness: '/textures/Trotuar_Plitka_Roughness.jpg',
      normalMap: '/textures/Trotuar_Plitka_Normal.jpg',
      color: '#fff',
    },
    {
      baseColor: '/textures/Trotuar_Plitka_BaseColor.jpg',
      roughness: '/textures/Trotuar_Plitka_Roughness.jpg',
      normalMap: '/textures/Trotuar_Plitka_Normal.jpg',
      color: '#fff',
    },

    // ... добавьте остальные материалы для тумбы
  ],
  onepolkrai: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для тумбы
  ],
  onepolcenter: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для тумбы
  ],
  tumbavertical: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для тумбы
  ],
  tumbagorizontal: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для тумбы
  ],
  zabor: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для тумбы
  ],
  zvetnik: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для тумбы
  ],
  nadgrobka: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  stelavertical: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  stelagorizontal: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
      color: '#ff0000',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
      color: '#ff0000',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  gravirovkaimages: [
    {
      baseColor: '/textures/gravirovka-images/rozy.png',
      alphaMap: '/textures/gravirovka-images/rozy.png', // Путь к альфа-карте (маске)
    },
    {
      baseColor: '/textures/gravirovka-images/gvozdika.png',
      alphaMap: '/textures/gravirovka-images/gvozdika.png', // Путь к альфа-карте (маске)
    },

    // Добавьте остальные изображения и их маски
  ],
  
};

export default materials;