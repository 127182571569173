// DateInput.jsx

import React, { useState } from 'react';

const DateInput = ({ onSubmitDate }) => {
  const [date, setDate] = useState('');

  const handleSubmitDate = () => {
    onSubmitDate(date);
  };

  return (
    <div>
      <input type="text" value={date} onChange={(e) => setDate(e.target.value)} />
      <button onClick={handleSubmitDate}>Готово</button>
    </div>
  );
};

export default DateInput;