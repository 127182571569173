// ImageOnCanvas.jsx
import React, { useRef, useEffect } from 'react';
import { useLoader, useThree } from '@react-three/fiber';
import { TextureLoader } from 'three';

const ImageOnCanvas = ({ imageUrl, maskUrl, positionY, positionZ, scale }) => {
  const texture = useLoader(TextureLoader, imageUrl);
  const alphaMap = useLoader(TextureLoader, maskUrl); // Загрузка маски
  const meshRef = useRef();

  useEffect(() => {
    if (texture && alphaMap) {
      texture.needsUpdate = true;
      alphaMap.needsUpdate = true;
    }
  }, [texture, alphaMap]);

  return (
    <mesh 
      ref={meshRef} 
      scale={[scale, scale, scale]} // Применяем масштаб
      position={[0.7151, positionY, positionZ]} // Используйте positionY для установки позиции Y
      rotation={[0, Math.PI / -2, 0]} // Поворот на 90 градусов вокруг оси Y
    >
      <planeBufferGeometry attach="geometry" args={[0.4, 0.4]} />
      <meshStandardMaterial attach="material" map={texture} alphaMap={alphaMap} transparent />
    </mesh>
  );
};

export default ImageOnCanvas;
