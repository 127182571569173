// Fio.jsx
import React, { useState } from 'react';

const Fio = ({ onSubmit }) => {
  const [text, setText] = useState('');

  const handleSubmit = () => {
    onSubmit(text);
  };

  return (
    <div>
      <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
      <button onClick={handleSubmit}>Готово</button>
    </div>
  );
};

export default Fio;