// Name.jsx
import React, { useState } from 'react';

const Name = ({ onSubmitName }) => {
  const [name, setName] = useState('');

  const handleSubmitName = () => {
    onSubmitName(name);
  };

  return (
    <div>
      <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      <button onClick={handleSubmitName}>Готово</button>
    </div>
  );
};

export default Name;