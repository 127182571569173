// Tumbavertical.jsx
import React, { useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader, RepeatWrapping, sRGBEncoding } from 'three';
import materials from './materials';

const Tumbavertical = ({ tumbaverticalMaterialIndex }) => {
  const { scene } = useThree();
  const modelRef = useRef();
  const textureLoader = new TextureLoader();

  useEffect(() => {
    // Загрузка и добавление модели в сцену один раз
    const loader = new GLTFLoader();
    loader.load('/mesh/tumbavertical.glb', (gltf) => {
      modelRef.current = gltf.scene;
      scene.add(gltf.scene);
      modelRef.current.position.set(0.75, 0.175, 0);
      updateTextures(tumbaverticalMaterialIndex);
    });

    return () => {
      // Удаление модели из сцены при размонтировании компонента
      if (modelRef.current) {
        scene.remove(modelRef.current);
      }
    };
  }, [scene]);

  useEffect(() => {
    updateTextures(tumbaverticalMaterialIndex);
  }, [tumbaverticalMaterialIndex]);

  const updateTextures = (materialIndex) => {
    if (!modelRef.current) return;
    const materialConfig = materials.tumbavertical[materialIndex];
    if (!materialConfig) return;

    // Задаем масштаб для текстур
    const textureScale = materialConfig.scale || 1; // Используйте значение scale из materialConfig, если оно есть

    const onLoadTexture = (texture) => {
      texture.encoding = sRGBEncoding;
      texture.wrapS = texture.wrapT = RepeatWrapping;
      texture.repeat.set(textureScale, textureScale); // Установка масштаба текстуры
      texture.needsUpdate = true;
    };

    const baseColorMap = textureLoader.load(materialConfig.baseColor, onLoadTexture);
    const roughnessMap = textureLoader.load(materialConfig.roughness, onLoadTexture);
    const normalMap = textureLoader.load(materialConfig.normalMap, onLoadTexture);

    modelRef.current.traverse((child) => {
      if (child.isMesh) {
        child.material.map = baseColorMap;
        child.material.roughnessMap = roughnessMap;
        child.material.normalMap = normalMap;
        child.material.metalness =  0.6;
        child.material.needsUpdate = true;
      }
    });
  };

  return null;
};

export default Tumbavertical;