// Twoplaces.jsx
import React, { useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader, RepeatWrapping, sRGBEncoding } from 'three';
import Tumbavertical from './Tumbavertical';
import materials from './materials';

const Twoplaces = ({ materialIndex, tumbaverticalMaterialIndex, position }) => {
  const { scene } = useThree();
  const twoplacesRef = useRef();

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load('/mesh/base_two.glb', (gltf) => {
      const model = gltf.scene;
      twoplacesRef.current = model;
      scene.add(model);

      const materialConfig = materials.twoplaces[materialIndex];
      const textureLoader = new TextureLoader();
      const baseColorMap = textureLoader.load(materialConfig.baseColor);
      const roughnessMap = textureLoader.load(materialConfig.roughness);
      const normalMap = textureLoader.load(materialConfig.normalMap);

      // Устанавливаем sRGBEncoding для корректного отображения цвета
      baseColorMap.encoding = sRGBEncoding;
      roughnessMap.encoding = sRGBEncoding;
      normalMap.encoding = sRGBEncoding;

      baseColorMap.wrapS = baseColorMap.wrapT = RepeatWrapping;
      roughnessMap.wrapS = roughnessMap.wrapT = RepeatWrapping;
      normalMap.wrapS = normalMap.wrapT = RepeatWrapping;

      const scale = 6; // Задаем масштаб текстуры
      baseColorMap.repeat.set(scale, scale);
      roughnessMap.repeat.set(scale, scale);
      normalMap.repeat.set(scale, scale);

      model.traverse((child) => {
        if (child.isMesh) {
          child.material.map = baseColorMap;
          child.material.roughnessMap = roughnessMap;
          child.material.normalMap = normalMap;
          child.material.metalness = 0;
          child.material.roughness = 1;
          child.material.needsUpdate = true;
        }
      });

      model.position.set(...position);
    });

    return () => {
      // Очистка при демонтировании компонента
      if (twoplacesRef.current) {
        scene.remove(twoplacesRef.current);
      }
    };
  }, [scene, materialIndex,  position]);

  return (
    <Tumbavertical 
    tumbaverticalMaterialIndex={tumbaverticalMaterialIndex} 
    // position={[0.7, 0.18, 0]}
  />
  );
};

export default Twoplaces;