// App.jsx
import React, { useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Oneplace from './Oneplace';
import Tumbavertical from './Tumbavertical';
import Tumbagorizontal from './Tumbagorizontal';
import Onepolkrai from './Onepolkrai';
import Onepolcenter from './Onepolcenter';
import Trotuar from './Trotuar';
import Twoplaces from './Twoplaces';
import ColorIndicator from './ColorIndicator';
import materials from './materials';
import Background from './Background';
import Zabor from './Zabor';
import Zvetnik from './Zvetnik';
import Nadgrobka from './Nadgrobka';
import Stelavertical from './Stelavertical';
import Stelagorizontal from './Stelagorizontal';
import ImageOnCanvas from './ImageOnCanvas';
import Fio from './Fio';
import TextInCanvas from './TextInCanvas';
import Name from './Name';
import NameInCanvas from './NameInCanvas';
import DateInput from './DateInput';
import DateInCanvas from './DateInCanvas';
import EpitaphInput from './EpitaphInput';
import EpitaphInCanvas from './EpitaphInCanvas';
import GravirovkaImages from './GravirovkaImages';

const App = () => {
  const [oneplaceMaterialIndex, setOneplaceMaterialIndex] = useState(0);
  const [twoplacesMaterialIndex, setTwoplacesMaterialIndex] = useState(0);
  const [tumbaverticalMaterialIndex, setTumbaverticalMaterialIndex] = useState(0);
  const [tumbagorizontalMaterialIndex, setTumbagorizontalMaterialIndex] = useState(0);
  const [onepolkraiMaterialIndex, setOnepolkraiMaterialIndex] = useState(0);
  const [onepolcenterMaterialIndex, setOnepolcenterMaterialIndex] = useState(0);
  const [trotuarMaterialIndex, setTrotuarMaterialIndex] = useState(0);
  const [activeComponent, setActiveComponent] = useState('oneplace');

  const handleNextOneplaceMaterial = () => {
    setOneplaceMaterialIndex((current) => (current + 1) % materials.oneplace.length);
  };
  const handlePreviousOneplaceMaterial = () => {
    setOneplaceMaterialIndex((current) => (current - 1 + materials.oneplace.length) % materials.oneplace.length);
  };
  const handleNextTwoplacesMaterial = () => {
    setTwoplacesMaterialIndex((current) => (current + 1) % materials.twoplaces.length);
  };
  const handlePreviousTwoplacesMaterial = () => {
    setTwoplacesMaterialIndex((current) => (current - 1 + materials.twoplaces.length) % materials.twoplaces.length);
  };

  const handleNextTumbaverticalMaterial = () => {
    setTumbaverticalMaterialIndex((current) => (current + 1) % materials.tumbavertical.length);
  };
  const handlePreviousTumbaverticalMaterial = () => {
    setTumbaverticalMaterialIndex((current) => (current - 1 + materials.tumbavertical.length) % materials.tumbavertical.length);
  };


  const handleNextTumbagorizontalMaterial = () => {
    setTumbagorizontalMaterialIndex((current) => (current + 1) % materials.tumbagorizontal.length);
  };
  const handlePreviousTumbagorizontalMaterial = () => {
    setTumbagorizontalMaterialIndex((current) => (current - 1 + materials.tumbagorizontal.length) % materials.tumbagorizontal.length);
  };


  

  const handleNextOnepolkraiMaterial = () => {
    setOnepolkraiMaterialIndex((current) => (current + 1) % materials.onepolkrai.length);
  };
  const handlePreviousOnepolkraiMaterial = () => {
    setOnepolkraiMaterialIndex((current) => (current - 1 + materials.onepolkrai.length) % materials.onepolkrai.length);
  };

  const handleNextOnepolcenterMaterial = () => {
    setOnepolcenterMaterialIndex((current) => (current + 1) % materials.onepolcenter.length);
  };
  const handlePreviousOnepolcenterMaterial = () => {
    setOnepolcenterMaterialIndex((current) => (current - 1 + materials.onepolcenter.length) % materials.onepolcenter.length);
  };


  const [collectionIndex, setCollectionIndex] = useState(0);
  // Предполагаем, что у нас есть массив для каждой коллекции, и в каждом массиве индексы материалов для каждого меша
  const [meshMaterialIndices, setMeshMaterialIndices] = useState([
    [0, 0, 0], // Индексы для мешей первой коллекции
    [0, 0, 0], // Индексы для мешей второй коллекции
    [0, 0, 0], // Индексы для мешей второй коллекции
    // Добавляйте новые массивы по мере добавления коллекций
  ]);

  const handleNextCollection = () => {
    setCollectionIndex(prev => (prev + 1) % meshMaterialIndices.length);
  };

  const handlePreviousCollection = () => {
    setCollectionIndex(prev => (prev - 1 + meshMaterialIndices.length) % meshMaterialIndices.length);
  };

  const handleNextMeshMaterial = (collectionIdx, meshIdx) => {
    setMeshMaterialIndices(prev => {
      const updated = prev.map((collection, idx) => {
        if (idx === collectionIdx) {
          const updatedCollection = [...collection];
          updatedCollection[meshIdx] = (updatedCollection[meshIdx] + 1) % materials.zabor.length;
          return updatedCollection;
        }
        return collection;
      });
      return updated;
    });
  };

  const handlePreviousMeshMaterial = (collectionIdx, meshIdx) => {
    setMeshMaterialIndices(prev => {
      const updated = prev.map((collection, idx) => {
        if (idx === collectionIdx) {
          const updatedCollection = [...collection];
          updatedCollection[meshIdx] = (updatedCollection[meshIdx] - 1 + materials.zabor.length) % materials.zabor.length;
          return updatedCollection;
        }
        return collection;
      });
      return updated;
    });
  };

  const [isThirdMeshVisible, setIsThirdMeshVisible] = useState(true);
  const [tumbaOrientation, setTumbaOrientation] = useState('vertical'); // Добавьте это состояние

  const [zvetnikMaterialIndex, setZvetnikMaterialIndex] = useState(0);

  const handleNextZvetnikMaterial = () => {
    setZvetnikMaterialIndex((current) => (current + 1) % materials.zvetnik.length);
  };

  const handlePreviousZvetnikMaterial = () => {
    setZvetnikMaterialIndex((current) => (current - 1 + materials.zvetnik.length) % materials.zvetnik.length);
  };

  const [nadgrobkaMaterialIndex, setNadgrobkaMaterialIndex] = useState(0);

  const handleNextNadgrobkaMaterial = () => {
    setNadgrobkaMaterialIndex((current) => (current + 1) % materials.nadgrobka.length);
  };

  const handlePreviousNadgrobkaMaterial = () => {
    setNadgrobkaMaterialIndex((current) => (current - 1 + materials.nadgrobka.length) % materials.nadgrobka.length);
  };

  const [activeMeshIndex, setActiveMeshIndex] = useState(0);

  const [meshCount, setMeshCount] = useState(0);

  const handleNextMesh = () => {
    setActiveMeshIndex(prevIndex => (prevIndex + 1) % meshCount);
  };
  
  const handlePreviousMesh = () => {
    setActiveMeshIndex(prevIndex => prevIndex - 1 < 0 ? meshCount - 1 : prevIndex - 1);
  };

  const [stelaverticalMaterialIndex, setStelaverticalMaterialIndex] = useState(0);

  const handleNextStelaverticalMaterial = () => {
    setStelaverticalMaterialIndex((current) => (current + 1) % materials.stelavertical.length);
  };

  const handlePreviousStelaverticalMaterial = () => {
    setStelaverticalMaterialIndex((current) => (current - 1 + materials.stelavertical.length) % materials.stelavertical.length);
  };

  const [activeStelaMeshIndex, setActiveStelaMeshIndex] = useState(0);

  const [stelaMeshCount, setStelaMeshCount] = useState(0);

  const handleNextStelaMesh = () => {
    setActiveStelaMeshIndex(prevIndex => (prevIndex + 1) % stelaMeshCount);
  };
  
  const handlePreviousStelaMesh = () => {
    setActiveStelaMeshIndex(prevIndex => prevIndex - 1 < 0 ? stelaMeshCount - 1 : prevIndex - 1);
  };


  const [stelagorizontalMaterialIndex, setStelagorizontalMaterialIndex] = useState(0);

  const handleNextStelagorizontalMaterial = () => {
    setStelagorizontalMaterialIndex((current) => (current + 1) % materials.stelagorizontal.length);
  };

  const handlePreviousStelagorizontalMaterial = () => {
    setStelagorizontalMaterialIndex((current) => (current - 1 + materials.stelagorizontal.length) % materials.stelagorizontal.length);
  };

  const [activeStelaGorizontalMeshIndex, setActiveStelaGorizontalMeshIndex] = useState(0);

  const [stelaGorizontalMeshCount, setStelaGorizontalMeshCount] = useState(0);

  const handleNextStelaGorizontalMesh = () => {
    setActiveStelaGorizontalMeshIndex(prevIndex => (prevIndex + 1) % stelaGorizontalMeshCount);
  };
  
  const handlePreviousStelaGorizontalMesh = () => {
    setActiveStelaGorizontalMeshIndex(prevIndex => prevIndex - 1 < 0 ? stelaGorizontalMeshCount - 1 : prevIndex - 1);
  };


  // Состояние для хранения URL изображения
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePositionY, setImagePositionY] = useState(0.8); // Начальное значение Y позиции изображения
  const [imagePositionZ, setImagePositionZ] = useState(0); 
  const [imageScale, setImageScale] = useState(1);


  // Функция для обновления URL изображения после выбора файла
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImageUrl(url);
    }
  };

  const [text, setText] = useState('');
  const [fioPositionY, setFioPositionY] = useState(0.7);
  const [textPositionZ, setTextPositionZ] = useState(0); // Начальное значение Z позиции текста
  const [fioFontSize, setFioFontSize] = useState(0.04);
  const [displayText, setDisplayText] = useState('');

  const handleTextSubmit = (inputText) => {
    setDisplayText(inputText);
  };

  const [textName, setTextName] = useState('');
  const [showNameInCanvas, setShowNameInCanvas] = useState(false);
  const [namePositionY, setNamePositionY] = useState(0.64);
  const [namePositionZ, setNamePositionZ] = useState(0); // Начальное значение Z позиции текста
  const [nameFontSize, setNameFontSize] = useState(0.03); // Инициализация с начальным значением размера шрифта

  const handleNameSubmit = (inputName) => {
    setTextName(inputName);
    setShowNameInCanvas(true); // Показать имя в канвасе после нажатия кнопки "Готово"
  };

  const [date, setDate] = useState('');
  const [showDateInCanvas, setShowDateInCanvas] = useState(false); // Добавленное состояние для отображения даты
  const [datePositionY, setDatePositionY] = useState(0.58); // Начальное значение Y позиции даты
  const [datePositionZ, setDatePositionZ] = useState(0); // Начальное значение Z позиции текста даты
  const [dateFontSize, setDateFontSize] = useState(0.025); // Начальное значение размера шрифта даты

  const handleDateSubmit = (inputDate) => {
    setDate(inputDate);
    setShowDateInCanvas(true); // Установите true, чтобы показать дату в канвасе
  };

  const [epitaph, setEpitaph] = useState('');
  const [showEpitaphInCanvas, setShowEpitaphInCanvas] = useState(false);
  const [epitaphPositionY, setEpitaphPositionY] = useState(0.35); // Существующее или обновленное значение Y позиции эпитафии
  const [epitaphPositionZ, setEpitaphPositionZ] = useState(0); // Новое состояние для управления позицией по оси Z
  const [epitaphTextAlign, setEpitaphTextAlign] = useState('center');

  const handleEpitaphSubmit = (inputEpitaph) => {
    setEpitaph(inputEpitaph);
    setShowEpitaphInCanvas(true);
  };

  const [gravImagesIndex, setGravImagesIndex] = useState(0);
  const [gravImagesPositionY, setGravImagesPositionY] = useState(0.47); 
  const [gravImagesPositionZ, setGravImagesPositionZ] = useState(0);
  const [gravImagesScale, setGravImagesScale] = useState(1); // Начальное значение масштаба изображения гравировки
  const [isGravImagesVisible, setIsGravImagesVisible] = useState(true); // Начальное значение видимости изображения гравировки

  const handleNextGravImages = () => {
    setGravImagesIndex((prevIndex) => (prevIndex + 1) % materials.gravirovkaimages.length);
  };

  const handlePreviousGravImages = () => {
    setGravImagesIndex((prevIndex) => (prevIndex - 1 + materials.gravirovkaimages.length) % materials.gravirovkaimages.length);
  };

  return (
    <div className="construktor">
      <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
          {activeComponent === 'oneplace' && (
          <>
            <Oneplace 
              oneplaceMaterialIndex={oneplaceMaterialIndex} 
              position={[0, 0, 0]} 
            />
            <Onepolkrai 
              onepolkraiMaterialIndex={onepolkraiMaterialIndex}
            />
            <Onepolcenter 
              onepolcenterMaterialIndex={onepolcenterMaterialIndex}
            />
            <Zabor
              collectionIndex={collectionIndex}
              meshMaterialIndices={meshMaterialIndices[collectionIndex]}
              isThirdMeshVisible={isThirdMeshVisible}
            />
            <Trotuar 
              trotuarMaterialIndex={trotuarMaterialIndex}
            />
            {/* Переключение между Tumbavertical и Tumbagorizontal */}
            {tumbaOrientation === 'vertical' ? (
              <>
                <Tumbavertical 
                  tumbaverticalMaterialIndex={tumbaverticalMaterialIndex}
                />
                <Stelavertical
                stelaverticalMaterialIndex={stelaverticalMaterialIndex}
                activeMeshIndex={activeStelaMeshIndex}
                setMeshCount={setStelaMeshCount} // Передаем функцию для обновления stelaMeshCount
                />
                {imageUrl && <ImageOnCanvas imageUrl={imageUrl} maskUrl="/textures/portret-mask2.png" positionY={imagePositionY} positionZ={imagePositionZ} scale={imageScale} />}
                {displayText && <TextInCanvas text={displayText} positionY={fioPositionY} fontSize={fioFontSize} positionZ={textPositionZ} />}
                {showNameInCanvas && <NameInCanvas name={textName} positionY={namePositionY} positionZ={namePositionZ} fontSize={nameFontSize} />} 
                {showDateInCanvas && <DateInCanvas date={date} positionY={datePositionY} fontSize={dateFontSize} positionZ={datePositionZ} />}        
                {isGravImagesVisible && (
                  <GravirovkaImages
                    gravImagesIndex={gravImagesIndex}
                    gravImagesArray={materials.gravirovkaimages}
                    positionY={gravImagesPositionY}
                    positionZ={gravImagesPositionZ}
                    scale={gravImagesScale}
                  />
                )}
                {showEpitaphInCanvas && <EpitaphInCanvas epitaph={epitaph} position={[0.715, epitaphPositionY, epitaphPositionZ]} textAlign={epitaphTextAlign} />}
              </>
            ) : (
              <>
                <Tumbagorizontal 
                  tumbagorizontalMaterialIndex={tumbagorizontalMaterialIndex}
                />
                <Stelagorizontal
                  stelagorizontalMaterialIndex={stelagorizontalMaterialIndex}
                  activeMeshIndex={activeStelaGorizontalMeshIndex}
                  stelaGorizontalMeshCount={setStelaGorizontalMeshCount}
                />
                {imageUrl && <ImageOnCanvas imageUrl={imageUrl} maskUrl="/textures/portret-mask2.png" positionY={imagePositionY} positionZ={imagePositionZ} scale={imageScale} />}
                {displayText && <TextInCanvas text={displayText} positionY={fioPositionY} fontSize={fioFontSize} positionZ={textPositionZ} />}
                {showNameInCanvas && <NameInCanvas name={textName} positionY={namePositionY} positionZ={namePositionZ} fontSize={nameFontSize} />} 
                {showDateInCanvas && <DateInCanvas date={date} positionY={datePositionY} fontSize={dateFontSize} positionZ={datePositionZ} />}        
                {isGravImagesVisible && (
                  <GravirovkaImages
                    gravImagesIndex={gravImagesIndex}
                    gravImagesArray={materials.gravirovkaimages}
                    positionY={gravImagesPositionY}
                    positionZ={gravImagesPositionZ}
                    scale={gravImagesScale}
                  />
                )}
                {showEpitaphInCanvas && <EpitaphInCanvas epitaph={epitaph} position={[0.715, epitaphPositionY, epitaphPositionZ]} textAlign={epitaphTextAlign} />}
              </>
            )}
            <Zvetnik zvetnikMaterialIndex={zvetnikMaterialIndex} />
            <Nadgrobka
              nadgrobkaMaterialIndex={nadgrobkaMaterialIndex}
              activeMeshIndex={activeMeshIndex}
              setMeshCount={setMeshCount} // Передаем функцию для обновления meshCount
            />
          </>
        )}
        {activeComponent === 'twoplaces' && (
          <Twoplaces 
            materialIndex={twoplacesMaterialIndex} 
            position={[0, 0, 0]} 
          />
        )}
        <OrbitControls enableZoom={true} enablePan={true} />
        <Background hdrBackgroundPath="/textures/bg.hdr" hdrEnvironmentPath="/textures/envMap.hdr" />
      </Canvas>
      <div className="controls-content">
        <button onClick={() => setActiveComponent('oneplace')}>На одно место</button>
        <button onClick={() => setActiveComponent('twoplaces')}>На два места</button>

        {activeComponent === 'oneplace' && (
          <div>

            <div>
              <button onClick={() => setTumbaOrientation('vertical')}>Вертикальная стела</button>
              <button onClick={() => setTumbaOrientation('horizontal')}>Горизонтальная стела</button>
            </div>

            {tumbaOrientation === 'vertical' ? (
              <>
                <div>
                  <button onClick={handlePreviousStelaverticalMaterial}>Назад</button>
                  <ColorIndicator materialIndex={stelaverticalMaterialIndex} type="stelavertical" />
                  <button onClick={handleNextStelaverticalMaterial}>Вперёд</button> Стела: Цвет
                </div>
                <div>
                  <button onClick={handlePreviousStelaMesh}>Назад</button>
                  <button onClick={handleNextStelaMesh}>Вперёд</button> Стела: Форма
                </div>
                <div>
                  <button onClick={handlePreviousTumbaverticalMaterial}>Назад</button> 
                  <ColorIndicator materialIndex={tumbaverticalMaterialIndex} type="tumbavertical" />
                  <button onClick={handleNextTumbaverticalMaterial}>Вперёд</button> Тумба: Цвет
                </div>
                <div>
                  <input type="file" onChange={handleImageUpload} accept="image/*" />
                </div>
                <div>
                  <label htmlFor="imageScale">Масштаб изображения:</label>
                  <input
                    id="imageScale"
                    type="range"
                    min="0.1" // Минимальное значение масштаба
                    max="2"   // Максимальное значение масштаба
                    value={imageScale}
                    onChange={(e) => setImageScale(Number(e.target.value))}
                    step="0.1" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="imagePositionY">Высота изображения:</label>
                  <input
                    id="imagePositionY"
                    type="range"
                    min="0.5" // Минимальное значение Y
                    max="1.1"  // Максимальное значение Y
                    value={imagePositionY}
                    onChange={(e) => setImagePositionY(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="imagePositionZ">Ширина изображения:</label>
                  <input
                    id="imagePositionZ"
                    type="range"
                    min="-1" // Минимальное значение Z
                    max="1"  // Максимальное значение Z
                    value={imagePositionZ}
                    onChange={(e) => setImagePositionZ(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>
                Фамилия: <Fio onSubmit={handleTextSubmit} /> 
                <div>
                  <label htmlFor="fioPositionY">Высота текста:</label>
                  <input
                    id="fioPositionY"
                    type="range"
                    min="0.4" // Минимальное значение Y
                    max="1"  // Максимальное значение Y
                    value={fioPositionY}
                    onChange={(e) => setFioPositionY(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="textPositionZ">Ширина текста:</label>
                  <input
                    id="textPositionZ"
                    type="range"
                    min="-0.33" // Минимальное значение Z
                    max="0.33"  // Максимальное значение Z
                    value={textPositionZ}
                    onChange={(e) => setTextPositionZ(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="fioFontSize">Размер шрифта Фамилия:</label>
                  <input
                    id="fioFontSize"
                    type="range"
                    min="0.01" // Минимальное значение размера шрифта
                    max="0.1"  // Максимальное значение размера шрифта
                    value={fioFontSize}
                    onChange={(e) => setFioFontSize(Number(e.target.value))}
                    step="0.005" // Шаг изменения значения
                  />
                </div>
                Имя Отчество: <Name onSubmitName={handleNameSubmit} />
                <div>
                  <label htmlFor="namePositionY">Высота имени:</label>
                  <input
                    id="namePositionY"
                    type="range"
                    min="0.3" // Минимальное значение Y
                    max="1"  // Максимальное значение Y
                    value={namePositionY}
                    onChange={(e) => setNamePositionY(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="nameFontSize">Размер шрифта имени:</label>
                  <input
                    id="nameFontSize"
                    type="range"
                    min="0.01" // Минимальное значение размера шрифта
                    max="0.1"  // Максимальное значение размера шрифта
                    value={nameFontSize}
                    onChange={(e) => setNameFontSize(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>
                Дата: <DateInput onSubmitDate={handleDateSubmit} />
                <div>
                  <label htmlFor="datePositionY">Высота даты:</label>
                  <input
                    id="datePositionY"
                    type="range"
                    min="0.3" // Минимальное значение Y
                    max="1"   // Максимальное значение Y
                    value={datePositionY}
                    onChange={(e) => setDatePositionY(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="datePositionZ">Позиция даты по оси Z:</label>
                  <input
                    id="datePositionZ"
                    type="range"
                    min="-0.35" // Минимальное значение Z
                    max="0.35"  // Максимальное значение Z
                    value={datePositionZ}
                    onChange={(e) => setDatePositionZ(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="dateFontSize">Размер шрифта даты:</label>
                  <input
                    id="dateFontSize"
                    type="range"
                    min="0.01" // Минимальное значение размера шрифта
                    max="0.1"  // Максимальное значение размера шрифта
                    value={dateFontSize}
                    onChange={(e) => setDateFontSize(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>
                <div>
                  <button onClick={() => setIsGravImagesVisible(!isGravImagesVisible)}>
                    {isGravImagesVisible ? 'Скрыть гравировку' : 'Показать гравировку'}
                  </button>
                </div>
                <div>
                  <button onClick={handlePreviousGravImages}>Назад</button>
                  <button onClick={handleNextGravImages}>Вперёд</button> Изображение:
                </div>
                <div>
                  <label htmlFor="gravImagesPositionY">Высота изображения гравировки:</label>
                  <input
                    id="gravImagesPositionY"
                    type="range"
                    min="0.25" // Минимальное значение Y
                    max="1"   // Максимальное значение Y
                    value={gravImagesPositionY}
                    onChange={(e) => setGravImagesPositionY(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="gravImagesPositionZ">Глубина изображения гравировки:</label>
                  <input
                    id="gravImagesPositionZ"
                    type="range"
                    min="-0.5" // Минимальное значение Z
                    max="0.5"  // Максимальное значение Z
                    value={gravImagesPositionZ}
                    onChange={(e) => setGravImagesPositionZ(Number(e.target.value))}
                    step="0.1" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="gravImagesScale">Масштаб изображения гравировки:</label>
                  <input
                    id="gravImagesScale"
                    type="range"
                    min="0.1" // Минимальное значение масштаба
                    max="2"   // Максимальное значение масштаба
                    value={gravImagesScale}
                    onChange={(e) => setGravImagesScale(Number(e.target.value))}
                    step="0.1" // Шаг изменения значения
                  />
                </div>
                Эпитафия: <EpitaphInput onSubmitEpitaph={handleEpitaphSubmit} />
                <div>
                  <label htmlFor="epitaphTextAlign">Выравнивание текста эпитафии:</label>
                  <select
                    id="epitaphTextAlign"
                    value={epitaphTextAlign}
                    onChange={(e) => setEpitaphTextAlign(e.target.value)}
                  >
                    <option value="left">Слева</option>
                    <option value="center">По центру</option>
                    <option value="right">Справа</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="epitaphPositionY">Высота эпитафии Y:</label>
                  <input
                    id="epitaphPositionY"
                    type="range"
                    min="0.3" // Минимальное значение Y
                    max="1"   // Максимальное значение Y
                    value={epitaphPositionY}
                    onChange={(e) => setEpitaphPositionY(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="epitaphPositionZ">Ширина эпитафии Z:</label>
                  <input
                    id="epitaphPositionZ"
                    type="range"
                    min="-0.2" // Минимальное значение Z
                    max="0.2"  // Максимальное значение Z
                    value={epitaphPositionZ}
                    onChange={(e) => setEpitaphPositionZ(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>

              </>
            ) : (
              <>
                <div>
                  <button onClick={handlePreviousStelagorizontalMaterial}>Назад</button>
                  <ColorIndicator materialIndex={stelagorizontalMaterialIndex} type="stelagorizontal" />
                  <button onClick={handleNextStelagorizontalMaterial}>Вперёд</button> Горизонтальная стела: Цвет
                </div>
                <div>
                  <button onClick={handlePreviousStelaGorizontalMesh}>Назад</button>
                  <button onClick={handleNextStelaGorizontalMesh}>Вперёд</button> Горизонтальная стела: Форма
                </div>
                <div>
                  <button onClick={handlePreviousTumbagorizontalMaterial}>Назад</button> 
                  <ColorIndicator materialIndex={tumbaverticalMaterialIndex} type="tumbagorizontal" />
                  <button onClick={handleNextTumbagorizontalMaterial}>Вперёд</button> Тумба: Цвет
                </div>


                <div>
                  <input type="file" onChange={handleImageUpload} accept="image/*" />
                </div>
                <div>
                  <label htmlFor="imageScale">Масштаб изображения:</label>
                  <input
                    id="imageScale"
                    type="range"
                    min="0.1" // Минимальное значение масштаба
                    max="2"   // Максимальное значение масштаба
                    value={imageScale}
                    onChange={(e) => setImageScale(Number(e.target.value))}
                    step="0.1" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="imagePositionY">Высота изображения:</label>
                  <input
                    id="imagePositionY"
                    type="range"
                    min="0.5" // Минимальное значение Y
                    max="1.1"  // Максимальное значение Y
                    value={imagePositionY}
                    onChange={(e) => setImagePositionY(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="imagePositionZ">Ширина изображения:</label>
                  <input
                    id="imagePositionZ"
                    type="range"
                    min="-0.35" // Минимальное значение Z
                    max="0.35"  // Максимальное значение Z
                    value={imagePositionZ}
                    onChange={(e) => setImagePositionZ(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>
                Фамилия: <Fio onSubmit={handleTextSubmit} /> 
                <div>
                  <label htmlFor="fioPositionY">Высота текста:</label>
                  <input
                    id="fioPositionY"
                    type="range"
                    min="0.4" // Минимальное значение Y
                    max="1"  // Максимальное значение Y
                    value={fioPositionY}
                    onChange={(e) => setFioPositionY(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="textPositionZ">Ширина текста:</label>
                  <input
                    id="textPositionZ"
                    type="range"
                    min="-0.33" // Минимальное значение Z
                    max="0.33"  // Максимальное значение Z
                    value={textPositionZ}
                    onChange={(e) => setTextPositionZ(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="fioFontSize">Размер шрифта Фамилия:</label>
                  <input
                    id="fioFontSize"
                    type="range"
                    min="0.01" // Минимальное значение размера шрифта
                    max="0.1"  // Максимальное значение размера шрифта
                    value={fioFontSize}
                    onChange={(e) => setFioFontSize(Number(e.target.value))}
                    step="0.005" // Шаг изменения значения
                  />
                </div>
                Имя Отчество: <Name onSubmitName={handleNameSubmit} />
                <div>
                  <label htmlFor="namePositionY">Высота имени:</label>
                  <input
                    id="namePositionY"
                    type="range"
                    min="0.3" // Минимальное значение Y
                    max="1"  // Максимальное значение Y
                    value={namePositionY}
                    onChange={(e) => setNamePositionY(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="namePositionZ">Позиция имени по оси Z:</label>
                  <input
                    id="namePositionZ"
                    type="range"
                    min="-0.33" // Минимальное значение Z
                    max="0.33"  // Максимальное значение Z
                    value={namePositionZ}
                    onChange={(e) => setNamePositionZ(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="nameFontSize">Размер шрифта имени:</label>
                  <input
                    id="nameFontSize"
                    type="range"
                    min="0.01" // Минимальное значение размера шрифта
                    max="0.1"  // Максимальное значение размера шрифта
                    value={nameFontSize}
                    onChange={(e) => setNameFontSize(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>
                Дата: <DateInput onSubmitDate={handleDateSubmit} />
                <div>
                  <label htmlFor="datePositionY">Высота даты:</label>
                  <input
                    id="datePositionY"
                    type="range"
                    min="0.3" // Минимальное значение Y
                    max="1"   // Максимальное значение Y
                    value={datePositionY}
                    onChange={(e) => setDatePositionY(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="datePositionZ">Позиция по ширине:</label>
                  <input
                    id="datePositionZ"
                    type="range"
                    min="-0.35" // Минимальное значение Z
                    max="0.35"  // Максимальное значение Z
                    value={datePositionZ}
                    onChange={(e) => setDatePositionZ(Number(e.target.value))}
                    step="0.01" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="dateFontSize">Размер шрифта даты:</label>
                  <input
                    id="dateFontSize"
                    type="range"
                    min="0.01" // Минимальное значение размера шрифта
                    max="0.1"  // Максимальное значение размера шрифта
                    value={dateFontSize}
                    onChange={(e) => setDateFontSize(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>
                <div>
                  <button onClick={() => setIsGravImagesVisible(!isGravImagesVisible)}>
                    {isGravImagesVisible ? 'Скрыть гравировку' : 'Показать гравировку'}
                  </button>
                </div>
                <div>
                  <button onClick={handlePreviousGravImages}>Назад</button>
                  <button onClick={handleNextGravImages}>Вперёд</button> Изображение:
                </div>
                <div>
                  <label htmlFor="gravImagesPositionY">Высота изображения гравировки:</label>
                  <input
                    id="gravImagesPositionY"
                    type="range"
                    min="0.25" // Минимальное значение Y
                    max="1"   // Максимальное значение Y
                    value={gravImagesPositionY}
                    onChange={(e) => setGravImagesPositionY(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="gravImagesPositionZ">Глубина изображения гравировки:</label>
                  <input
                    id="gravImagesPositionZ"
                    type="range"
                    min="-0.5" // Минимальное значение Z
                    max="0.5"  // Максимальное значение Z
                    value={gravImagesPositionZ}
                    onChange={(e) => setGravImagesPositionZ(Number(e.target.value))}
                    step="0.1" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="gravImagesScale">Масштаб изображения гравировки:</label>
                  <input
                    id="gravImagesScale"
                    type="range"
                    min="0.1" // Минимальное значение масштаба
                    max="2"   // Максимальное значение масштаба
                    value={gravImagesScale}
                    onChange={(e) => setGravImagesScale(Number(e.target.value))}
                    step="0.1" // Шаг изменения значения
                  />
                </div>
                Эпитафия: <EpitaphInput onSubmitEpitaph={handleEpitaphSubmit} />
                <div>
                  <label htmlFor="epitaphTextAlign">Выравнивание текста эпитафии:</label>
                  <select
                    id="epitaphTextAlign"
                    value={epitaphTextAlign}
                    onChange={(e) => setEpitaphTextAlign(e.target.value)}
                  >
                    <option value="left">Слева</option>
                    <option value="center">По центру</option>
                    <option value="right">Справа</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="epitaphPositionY">Высота эпитафии Y:</label>
                  <input
                    id="epitaphPositionY"
                    type="range"
                    min="0.27" // Минимальное значение Y
                    max="1"   // Максимальное значение Y
                    value={epitaphPositionY}
                    onChange={(e) => setEpitaphPositionY(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>
                <div>
                  <label htmlFor="epitaphPositionZ">Ширина эпитафии Z:</label>
                  <input
                    id="epitaphPositionZ"
                    type="range"
                    min="-0.37" // Минимальное значение Z
                    max="0.37"  // Максимальное значение Z
                    value={epitaphPositionZ}
                    onChange={(e) => setEpitaphPositionZ(Number(e.target.value))}
                    step="0.001" // Шаг изменения значения
                  />
                </div>

              </>
            )}

            <div>
              <button onClick={handlePreviousZvetnikMaterial}>Назад</button>
              <ColorIndicator materialIndex={zvetnikMaterialIndex} type="zvetnik" />
              <button onClick={handleNextZvetnikMaterial}>Вперёд</button> Цветник: Цвет
            </div>

            <div>
              <button onClick={handlePreviousNadgrobkaMaterial}>Назад</button>
              <ColorIndicator materialIndex={nadgrobkaMaterialIndex} type="nadgrobka" />
              <button onClick={handleNextNadgrobkaMaterial}>Вперёд</button> Надгробка: Цвет
            </div>
            <div>
              <button onClick={handlePreviousMesh}>Назад</button>
              <button onClick={handleNextMesh}>Вперёд</button> Надгробка: Форма
            </div>

            <div>
              <button onClick={handlePreviousOnepolkraiMaterial}>Назад</button>
              <ColorIndicator materialIndex={onepolkraiMaterialIndex} type="onepolkrai" />
              <button onClick={handleNextOnepolkraiMaterial}>Вперёд</button> Пол край: Цвет
            </div>
            <div>
              <button onClick={handlePreviousOnepolcenterMaterial}>Назад</button>
              <ColorIndicator materialIndex={onepolcenterMaterialIndex} type="onepolcenter" />
              <button onClick={handleNextOnepolcenterMaterial}>Вперёд</button> Пол центр: Цвет
            </div>
            <div>
              <button onClick={handlePreviousOneplaceMaterial}>Назад</button> 
              <ColorIndicator materialIndex={oneplaceMaterialIndex} type="oneplace" />
              <button onClick={handleNextOneplaceMaterial}>Вперёд</button> Цоколь: Цвет
            </div>

            <div>
              <div>
                <button onClick={handlePreviousCollection}>Назад</button>
                <button onClick={handleNextCollection}>Вперёд</button> Форма ограды
              </div>

              <div>
                <button onClick={() => setIsThirdMeshVisible(!isThirdMeshVisible)}>
                  {isThirdMeshVisible ? "Убрать шар" : "Добавить шар"}
                </button>
              </div>
       
              {meshMaterialIndices[collectionIndex].map((_, idx) => (
                <React.Fragment key={idx}>
                  <div>
                    <button onClick={() => handlePreviousMeshMaterial(collectionIndex, idx)}>Назад </button>
                    <button onClick={() => handleNextMeshMaterial(collectionIndex, idx)}>Вперёд </button> Цвет {idx + 1}
                  </div>
                </React.Fragment>
              ))}
            </div>
        
          </div>
        )}
        {activeComponent === 'twoplaces' && (
          <div>
            <button onClick={handlePreviousTwoplacesMaterial}>Previous Material</button>
            <ColorIndicator materialIndex={twoplacesMaterialIndex} type="twoplaces" />
            <button onClick={handleNextTwoplacesMaterial}>Next Material</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;