// Stelavertical.jsx
import React, { useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader, RepeatWrapping, sRGBEncoding, MeshStandardMaterial } from 'three';
import materials from './materials';

const Stelavertical = ({ stelaverticalMaterialIndex, activeMeshIndex, setMeshCount }) => {
  const { scene } = useThree();
  const modelRef = useRef();
  const textureLoader = new TextureLoader();

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load('/mesh/oneplace/stelyvertical.glb', (gltf) => {
      modelRef.current = gltf.scene;
      scene.add(modelRef.current);
      modelRef.current.position.set(0.158, 0.187, 0); // Позиционирование модели может потребовать корректировки

      let count = 0;
      modelRef.current.traverse((child) => {
        if (child.isMesh) {
          count += 1;
          child.visible = false; // Сначала скрываем все меши
        }
      });

      setMeshCount(count); // Установка количества мешей

      if (modelRef.current.children[activeMeshIndex]) {
        modelRef.current.children[activeMeshIndex].visible = true; // Делаем видимым только активный меш
      }

      updateMeshMaterials();
    });

    return () => {
      if (modelRef.current) {
        scene.remove(modelRef.current);
      }
    };
  }, [scene, activeMeshIndex, stelaverticalMaterialIndex, setMeshCount]);

  const updateMeshMaterials = () => {
    if (!modelRef.current) return;
    const materialConfig = materials.stelavertical[stelaverticalMaterialIndex];
    if (!materialConfig) return;

    const onLoadTexture = (texture) => {
      texture.encoding = sRGBEncoding;
      texture.wrapS = texture.wrapT = RepeatWrapping;
      texture.repeat.set(materialConfig.scale || 2, materialConfig.scale || 2);
      texture.needsUpdate = true;
    };

    const baseColorMap = textureLoader.load(materialConfig.baseColor, onLoadTexture);
    const roughnessMap = textureLoader.load(materialConfig.roughness, onLoadTexture);
    const normalMap = textureLoader.load(materialConfig.normalMap, onLoadTexture);

    const newMaterial = new MeshStandardMaterial({
      map: baseColorMap,
      roughnessMap: roughnessMap,
      normalMap: normalMap,
      metalness: materialConfig.metalness || 0.6,
      roughness: materialConfig.roughnessValue || 1,
    });

    modelRef.current.children.forEach((child, index) => {
      if (child.isMesh) {
        child.material = newMaterial;
        child.visible = index === activeMeshIndex; // Обновляем видимость в соответствии с активным мешем
      }
    });
  };

  return null;
};

export default Stelavertical;