// EpitaphInCanvas.jsx
import React from 'react';
import { Text } from '@react-three/drei';

const EpitaphInCanvas = ({ epitaph, position, textAlign }) => {
  return (
    <Text
      color="white" // Цвет текста
      fontSize={0.03} // Размер шрифта в единицах сцены
      fontStyle="italic"
      position={position} // Позиционирование текста в сцене
      rotation={[0, Math.PI / -2, 0]} // Поворот текста
      maxWidth={2} // Максимальная ширина текста
      lineHeight={1} // Высота строки
      textAlign={textAlign} // Выравнивание текста, принимаемое из пропса
    >
      {epitaph}
    </Text>
  );
};

export default EpitaphInCanvas;