// NameInCanvas.jsx
import React from 'react';
import { Text } from '@react-three/drei';

const NameInCanvas = ({ name, positionY, fontSize, positionZ }) => {
  return (
    <Text
      color="white" // Цвет текста
      fontSize={fontSize} // Используйте fontSize для установки размера шрифта
      position={[0.715, positionY, positionZ]} // Используйте positionY для установки позиции Y
      rotation={[0, Math.PI / -2, 0]} // Поворот текста
    >
      {name}
    </Text>
  );
};

export default NameInCanvas;