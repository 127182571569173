// DateInCanvas.jsx
import React from 'react';
import { Text } from '@react-three/drei';

const DateInCanvas = ({ date, positionY, fontSize, positionZ }) => {
  return (
    <Text
      color="white" // Цвет текста
      fontSize={fontSize} // Размер шрифта в единицах сцены, принимаемый из пропса
      position={[0.715, positionY, positionZ ]} // Позиционирование текста в сцене, принимаемое из пропса
      rotation={[0, Math.PI / -2, 0]} // Поворот текста
    >
      {date}
    </Text>
  );
};

export default DateInCanvas;